
import React from 'react';
import ModernUploadPage from './components/ModernUploadPage';

function App() {
  return (
    <div className="App">
      <ModernUploadPage />
    </div>
  );
}

export default App;
