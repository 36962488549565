import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, OAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyC-NZ2lifEXlZtxzFTfO2qvqiHE7GNdQCE",
    authDomain: "fakeless-5788f.firebaseapp.com",
    projectId: "fakeless-5788f",
    storageBucket: "fakeless-5788f.appspot.com",
    messagingSenderId: "277732122635",
    appId: "1:277732122635:web:96cfe4f113ac33cf1b270e",
    measurementId: "G-XFNKE8RL11"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const microsoftProvider = new OAuthProvider('microsoft.com');

export { auth, googleProvider, microsoftProvider, signInWithPopup };